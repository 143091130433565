import React from "react"
import { Head, Layout, Breadcrumbs } from "@common"
import "./styles/page.scss"

const Page = ({ pageContext, location }) => {
  let coupon = null
  if (location.search.includes("coupon") || location.search.includes("ref")) {
    coupon = location.search.split("=")[1]
  }
  if (coupon != null) {
    localStorage.setItem("urlCoupon", coupon)
  }
  const { page } = pageContext
  return (
    <Layout title={page.title}>
      <Head
        seo={pageContext.page.seo}
        siteMetaData={pageContext.page.siteMetaData}
      />
      <div className="page">
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col" />
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-8">
                <Breadcrumbs
                  elements={[
                    {
                      label: page.title,
                      url: `/${page.slug}/`,
                    },
                  ]}
                />
                <div
                  className="page-inner"
                  dangerouslySetInnerHTML={{
                    __html: page.content,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
